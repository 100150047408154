.contact-details {
  color: var(--text-color1);
  font-weight: 600;
}

.mr-top {
  margin-top: 1rem;
}
.contact-label {
  display: block;
  margin-top: 0.5rem;
}
