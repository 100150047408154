.react-tabs__tab--selected {
  /* border: none; */
  background: transparent;
  border-bottom: 2px solid var(--first-color);
  color: var(--first-color);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.react-tabs__tab:focus:after {
  background: transparent;
}

.react-tabs__tab-list {
  font-size: 14px;
}
