.footer {
  background: var(--body-color1);
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: var(--box-shadow-footer);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  font-size: 14px;
}
