.welcome {
  font-size: 1.5rem;
  height: 5rem;
  border: 1px solid var(--second-color);
  border-radius: 2px;
  vertical-align: bottom;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-weight: 600;
  background-color: var(--second-color);
  color: var(--third-color);
}
