.header {
  position: sticky;
  width: 100%;
  top: 0;
  margin-top: 1rem;
  left: 0;
  z-index: var(--z-fixed);
  box-shadow: var(--box-shadow2);
  overflow: hidden;
  transition: height 0.8s ease-in-out;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* height: var(--header-height); */
  padding: 0 1.5rem;
}

.nav-logo {
  color: var(--first-color);
  font-size: var(--h2-font-size);
}

.nav-open,
.nav-close {
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.nav-cta {
  display: block;
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  padding: 0.5rem 1.5rem;
  width: max-content;
  color: var(--body-color1);
}

.nav-header-open-height {
  height: var(--header-height);
}
.nav-header-close-height {
  height: 100vh;
}

.nav-list {
  display: flex;
  align-items: center;
  gap: 2rem;
  height: var(--header-height);
}

.nav-link {
  color: var(--title-color);
  height: var(--header-height);
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
}

.active {
  border-bottom: 2px solid var(--first-color);
  color: var(--third-color);
}

.nav-link:hover {
  color: var(--third-color);
}

.nav-close,
.nav-open {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.logo-image {
  width: 3rem;
}

.nav-header-transparent {
  background-color: var(--header-bg-color1);
}

.nav-header-bg-color {
  background-color: var(--body-color1);
}

.nav-item {
  width: max-content;
  color: var(--first-color);
}
