#google_translate_element {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 0px;
  padding-right: 1.5rem;
}
/* #google_translate_element span {
  display: none !important;
} */

/* .skiptranslate span {
  display: none !important;
} */

iframe {
  display: none !important;
  position: relative;
  top: -40px
}