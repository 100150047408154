.card-container {
  width: 48%;
}

.card-header-align-text {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.card-header-text {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color1);
}

.card-header-border {
  border: 1px solid var(--baner-color1);
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-header-height {
  height: 3rem;
}

.news-color {
  background-color: var(--baner-color1);
}
.about-color {
  background-color: var(--baner-color2);
}

.card-height {
  height: 20rem;
}
.card-image-border-left-right {
  border-left: 1px solid var(--image-border-color);
  border-right: 1px solid var(--image-border-color);
}
.card-footer-border-radius-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.card-footer-padding {
  padding: 1rem;
}
