.content {
  font-size: 16px;
}

.margin-top {
  margin-top: 1rem;
}
.about-guru-container {
  display: flex;
  justify-content: space-between;
}

.image-size {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.about-guru-image {
  width: 30%;
}
.about-guru-info {
  width: 68%;
}
.guru-history {
  color: #202122;
}
