@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --app-content-width: 96%;
  --header-height: 3.5rem;
  --body-container-margin-top: 4rem;
  --first-color: hsl(28, 88%, 62%);
  --second-color: #ffc885;
  --third-color: hsl(28, 88%, 40%);
  --text-color: #070707;
  --text-color1: rgba(0, 0, 0, 0.8);
  --baner-color1: #ff9c1d;
  --baner-color2: #ff8214;
  --image-border-color: rgba(0, 0, 0, 0.2);
  --body-color: #f5f5f5;
  --body-color1: #fff;
  --header-bg-color1: rgba(0, 0, 0, 0.85);
  --body-font: "Roboto Slab", serif;
  --second-font: "Dancing Script", "cursive";
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
  --box-shadow1: 0px 6px 12px rgba(30, 10, 58, 0.04);
  --box-shadow-footer: 0px -6px 12px rgba(30, 10, 58, 0.04);
  --box-shadow2: 0px 6px 25px rgba(30, 10, 58, 0.08);
  --box-shadow3: 0px 14px 40px rgba(30, 10, 58, 0.1);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.image-container-padding {
  padding: 1rem;
}

.image-border {
  border-radius: 4px;
}

.all-border {
  border: 1px solid var(--image-border-color);
}

.flex-wrap-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 1150px) {
  .card-container {
    width: 30rem !important;
    margin-bottom: 1rem;
  }
  .flex-wrap-container {
    justify-content: space-around;
  }

  .nav {
    display: block !important;
    width: 100%;
    min-height: var(--header-height);
  }

  .nav-menu {
    width: 100%;
  }

  .welcome {
    font-size: 1rem !important;
    height: 4rem !important;
  }
  .nav-logo {
    position: relative;
    left: 1.5rem;
  }

  .nav-list {
    display: block !important;
    padding-top: var(--header-height);
  }
  .image-container-padding {
    padding: 0.3rem;
  }

  .nav-logo {
    position: absolute;
    top: 0.2rem;
  }
  .about-guru-container {
    display: block !important;
  }
  .about-guru-image {
    width: 100% !important;
    text-align: center;
  }
  .about-guru-info {
    width: 100% !important;
  }
  .image-size {
    width: 18rem !important;
  }
}

@media screen and (min-width: 1150px) {
  .nav-open,
  .nav-close {
    display: none;
  }

  .nav-list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}
